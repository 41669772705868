import "./src/styles/global.css";

import * as React from "react";

import { ApolloProvider } from "@apollo/client";
import { AuthProvider } from "./src/hooks/useAuth";
import { client } from "./lib/apolloClient";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AuthProvider>{element}</AuthProvider>
  </ApolloProvider>
);
