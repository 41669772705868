import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import fetch from 'cross-fetch';

const httpLink = createHttpLink({
	uri: 'https://map.tactics4change.org/graphql',
	fetch: fetch,
	credentials: 'include',
});

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: httpLink,
});
